import React, { Component } from 'react';
import axios from 'axios';
import { APIURL, IMAGE_NOPHOTO_URL, IMAGE_UPLOAD_ERROR } from '../../../config/config';
import { connect } from 'react-redux';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DebtorsPay from '../DebtorsPay/DebtorsPay';
import ThaiBaht from 'thai-baht-text';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PreviewImage from '../../../views/PreviewImage';
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { AlertError, AlertSuccess, AlertWarning } from '../../Alert/Alert';
import NumberFormat from "react-number-format";
import { format } from 'date-fns';
import {th} from "date-fns/locale";
import { checkGroupPermission, getGroupPermission } from '../../../services/grouppermission';
import {DateUtil} from "../../../utils/dateUtil";
import {getDocTypeConfig} from "../../../services/companysetting";
import Loading from "../../Loading";
import DatePickerIcon from "../../DatePickerIcon";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew-Regular.ttf',
        bold: 'THSarabunNew-Bold.ttf',
        italics: 'THSarabunNew-Italic.ttf',
        bolditalics: 'THSarabunNew-BoldItalic.ttf'
    },
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    }
}

class DebtorsNotice extends Component {

    constructor(props) {
        super(props)

        this.state = {
            user: {},
            lists: [],
            load_data: true,
            isLoading: false,
            debtors: {},
            debtors_id: '',
            date_pay_start : '-',
            amount_of_debt: 0,

            notice_id: null,
            notice_no: '',
            notice_subject: 'บอกกล่าวให้ชำระหนี้/บอกเลิกสัญญา',
            startNoticeDate: new Date(),
            isOpenNoticeDate: false,
            notice_date_thai: null,
            notice_date: null,

            startPaymentForDate: new Date(),
            isOpenPaymentForDate: false,
            payment_for_date_thai: null,
            payment_for_date: null,

            startPaymentToDate: new Date(),
            isOpenPaymentToDate: false,
            payment_to_date_thai: null,
            payment_to_date: null,

            failure_pay_installment: '',
            total_period: '',
            notice_creditor: '',
            notice_tel_creditor: '',
            within_days: '',
            notice_payee: '',

            branch_id: '',
            car_id: '',
            customer_id: '',
            customer_name: '',
            car_sell_id: '',
            company_setting: null,

            branch_name : '',
            signatory : '',

            car_name: '',
            car_license_plate: '',
            car_color_name: '',
        }

        this.handleNoticeDate = this.handleNoticeDate.bind(this);
        this.handlePaymentForDate = this.handlePaymentForDate.bind(this);
        this.handlePaymentToDate = this.handlePaymentToDate.bind(this);
        this.loadCompanySetting = this.loadCompanySetting.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleNoticeDate (date) {
        let notice_date =  moment(date).format('YYYY-MM-DD')
        let notice_date_thai = DateUtil.convertLongDateThai(notice_date)

        this.setState({
            startNoticeDate: date,
            isOpenNoticeDate: false,
            notice_date: notice_date,
            notice_date_thai: notice_date_thai
        })
    }

    handlePaymentForDate (date) {
        let payment_for_date =  moment(date).format('YYYY-MM-DD')
        let payment_for_date_thai = DateUtil.convertLongDateThai(payment_for_date)

        this.setState({
            startPaymentForDate: date,
            isOpenPaymentForDate: false,
            payment_for_date: payment_for_date,
            payment_for_date_thai: payment_for_date_thai
        })
    }

    handlePaymentToDate (date) {
        let payment_to_date =  moment(date).format('YYYY-MM-DD')
        let payment_to_date_thai = DateUtil.convertLongDateThai(payment_to_date)

        this.setState({
            startPaymentToDate: date,
            isOpenPaymentToDate: false,
            payment_to_date: payment_to_date,
            payment_to_date_thai: payment_to_date_thai
        })
    }

    async loadCompanySetting () {
        await axios.get(APIURL + '/company_setting/1')
            .then(res => {
                let data = res.data
                let company_setting = data.company_setting
                this.setState({
                    company_name: company_setting.company_name,
                    company_address: company_setting.company_address,
                    company_tel: company_setting.company_tel
                })
            })
    }

    handleOnChange(e) {
        let name = e.target.name;
        let val = e.target.value;

        this.setState({
            [name]: val
        })
    }


    componentWillUnmount() {
        this.mounted = false
        // window.removeEventListener('resize', this.updateWindowDimensions);
    }

    async componentDidMount () {
        this.mounted = true;
        await this.loadCompanySetting();

    }

    render() {
        const {company_setting} = this.state

        return (
            <div className="row">
                <Loading isLoading={this.state.isLoading} />

                <div className="col-12">
                    <div className="card" style={styles.overflowY}>
                        <div className="card-header">
                            <strong className="text-title">ใบแจ้งเตือน </strong>
                        </div>

                        <div className="card-block">
                            <div className="row" style={{lineHeight: 2.0}}>
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td width={'30%'} style={styles.tdContentCenter} >
                                            &nbsp;
                                        </td>
                                        <td style={styles.tdContentCenter}>
                                            <strong style={{fontSize: 18}}> หนังสือบอกกล่าวทวงถาม </strong>
                                        </td>
                                        <td width={'30%'}>&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3} style={styles.tdContentCenter}>
                                            <div className="mt-3">
                                                บริษัท แอปซ่าส์ โซลูชัน จำกัด <br/>
                                                2 ซอยนครบาล 2 ถ.นครบาล ต.ในเมือง อ.เมือง จ.อุบลราชธานี 34000 <br/>
                                                หมายเลขผู้เสียภาษี &nbsp; 0345565000782 &nbsp;&nbsp; เบอร์โทรศัพท์ 085-4951802, 094-5035565
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td style={styles.tdContentRight}>
                                            <div className="row mt-5 mb-2">
                                                เลขที่
                                                <input className="form-control ml-4"
                                                       style={styles.inputDataNum}
                                                       value={this.state.notice_no || ''}
                                                       onChange={(e) => {
                                                           this.setState({
                                                               notice_no: e.target.value
                                                           })
                                                       }}
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td style={styles.tdContentRight}>
                                            <div className="row mb-5">
                                                วันที่ &nbsp;
                                                <input readOnly={true}
                                                       className="form-control ml-4"
                                                       style={{width: 160}}
                                                       value={this.state.notice_date_thai === null ? '' : this.state.notice_date_thai}
                                                />
                                                <div>
                                                    <DatePicker
                                                        selected={this.state.startNoticeDate}
                                                        onChange={this.handleNoticeDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        customInput={<DatePickerIcon />}
                                                    />
                                                </div>
                                                {
                                                    this.state.isOpenNoticeDate && (
                                                        <DatePicker
                                                            selected={this.state.startNoticeDate}
                                                            onChange={this.handleNoticeDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            inline
                                                        />
                                                    )
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row" style={{lineHeight: 3.0, paddingLeft: 60}}>
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline mt-3">
                                                เรื่อง&nbsp;
                                                <input type="text" style={styles.inputData}
                                                       name="notice_subject"
                                                       className="form-control ml-5"
                                                       placeholder=""
                                                       value={this.state.notice_subject}
                                                       onChange={this.handleOnChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                                เรียน
                                                <input type="text" style={styles.inputData}
                                                       name="customer_name"
                                                       className="form-control ml-5"
                                                       placeholder=""
                                                       value={this.state.customer_name}
                                                       onChange={this.handleOnChange}
                                                />
                                                &nbsp; &nbsp; ผู้เช่าซื้อ
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                                อ้างถึง &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;สัญญาเช่าซื้อรถยนต์ ฉบับลงวันที่&nbsp;
                                                <input readOnly={true}
                                                       className="form-control ml-4"
                                                       style={{width: 160}}
                                                       value={this.state.notice_date_thai === null ? '' : this.state.notice_date_thai}
                                                />
                                                <div>
                                                    <DatePicker
                                                        selected={this.state.startNoticeDate}
                                                        onChange={this.handleNoticeDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        customInput={<DatePickerIcon />}
                                                    />
                                                </div>
                                                {
                                                    this.state.isOpenNoticeDate && (
                                                        <DatePicker
                                                            selected={this.state.startNoticeDate}
                                                            onChange={this.handleNoticeDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            inline
                                                        />
                                                    )
                                                }
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline ml-5">
                                                &nbsp; &nbsp; &nbsp; &nbsp; สัญญาค้ำประกัน ฉบับลงวันที่
                                                <input readOnly={true}
                                                       className="form-control ml-5"
                                                       style={{width: 160}}
                                                       value={this.state.notice_date_thai === null ? '' : this.state.notice_date_thai}
                                                />
                                                <div>
                                                    <DatePicker
                                                        selected={this.state.startNoticeDate}
                                                        onChange={this.handleNoticeDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        customInput={<DatePickerIcon />}
                                                    />
                                                </div>
                                                {
                                                    this.state.isOpenNoticeDate && (
                                                        <DatePicker
                                                            selected={this.state.startNoticeDate}
                                                            onChange={this.handleNoticeDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            inline
                                                        />
                                                    )
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mt-3" style={{lineHeight: 3.5, paddingLeft: 60, paddingRight:60}}>
                                <table style={styles.table}>
                                    <tbody>
                                    <hr/>
                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                            ตามที่ท่านได้เช่าซื้อ รถยนต์คันหมายเลขทะเบียน
                                                <input type="text" style={styles.inputTax}
                                                       readOnly={true}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder="1บล 7846 พระนครศรีอยุธยา"
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                />
                                                ยี่ห้อ
                                                <input type="text" style={styles.inputData2}
                                                       readOnly={true}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                />
                                                แบบ
                                                <input type="text" style={styles.inputData2}
                                                       readOnly={true}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                />
                                                สี
                                                <input type="text" style={styles.inputData2}
                                                       readOnly={true}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                            จาก
                                                <input type="text" style={styles.inputDataDetail}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                />
                                                ผู้ให้เช่าซื้อ&nbsp;&nbsp;ในราคาเช่าซื้อ
                                                <input type="text" style={styles.inputDataNum}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                />บาท&nbsp;&nbsp;โดยแบ่งชำระเป็นรายงวดจำนวน
                                                <input type="text" style={styles.inputDataNo}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                />&nbsp;&nbsp;งวด
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                            โดยแบ่งชำระเป็นรายงวดจำนวน
                                                <input type="text" style={styles.inputDataNo}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       //value={}
                                                       onChange={this.handleOnChange}
                                                />งวด &nbsp;&nbsp;งวดละ
                                                <input type="text" style={styles.inputDataNum}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                />บาท&nbsp;&nbsp;กำหนดชำระทุกวันที่
                                                <input type="text" style={styles.inputDataNo}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                />ของทุกเดือน
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                                โดยกำหนดชำระงวดแรกวันที่
                                                <input readOnly={true}
                                                       className="form-control ml-2"
                                                       style={{width: 160}}
                                                       value={this.state.notice_date_thai === null ? '' : this.state.notice_date_thai}
                                                />
                                                <div>
                                                    <DatePicker
                                                        selected={this.state.startNoticeDate}
                                                        onChange={this.handleNoticeDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        customInput={<DatePickerIcon />}
                                                    />
                                                </div>
                                                {
                                                    this.state.isOpenNoticeDate && (
                                                        <DatePicker
                                                            selected={this.state.startNoticeDate}
                                                            onChange={this.handleNoticeDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            inline
                                                        />
                                                    )
                                                }&nbsp;&nbsp;ดังรายละเอียดในสัญญาเช่าซื้อ ตามเอกสารที่อ้างอิง และต่อมาท่านได้ผิดนัดชำระค่าเช่าซื้อตั้งแต่
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                                งวดที่
                                                <input type="text" style={styles.inputDataNo}
                                                       name="failure_pay_installment"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.failure_pay_installment || ''}
                                                       onChange={this.handleOnChange}
                                                />งวดประจำวันที่
                                                <input readOnly={true}
                                                       className="form-control ml-2"
                                                       style={{width: 160}}
                                                       value={this.state.payment_for_date_thai === null ? '' : this.state.payment_for_date_thai}
                                                />
                                                <div>
                                                    <DatePicker
                                                        selected={this.state.startPaymentForDate}
                                                        onChange={this.handlePaymentForDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        customInput={<DatePickerIcon />}
                                                    />
                                                </div>
                                                {
                                                    this.state.isOpenPaymentForDate && (
                                                        <DatePicker
                                                            selected={this.state.startPaymentForDate}
                                                            onChange={this.handlePaymentForDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            inline
                                                        />
                                                    )
                                                }&nbsp;&nbsp; - งวดประจำวันที่
                                                <input readOnly={true}
                                                       className="form-control ml-2"
                                                       style={{width: 160}}
                                                       value={this.state.payment_to_date_thai === null ? '' : this.state.payment_to_date_thai}
                                                />
                                                <div>
                                                    <DatePicker
                                                        selected={this.state.startPaymentToDate}
                                                        onChange={this.handlePaymentToDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        customInput={<DatePickerIcon />}
                                                    />
                                                </div>
                                                {
                                                    this.state.isOpenPaymentToDate && (
                                                        <DatePicker
                                                            selected={this.state.startPaymentToDate}
                                                            onChange={this.handlePaymentToDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            inline
                                                        />
                                                    )
                                                }&nbsp;&nbsp;และจนถึงปัจจุบัน เป็นระยะเวลา
                                                <input type="text" style={styles.inputDataNo}
                                                       name="total_period"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.total_period || ''}
                                                       onChange={this.handleOnChange}
                                                />งวด ติดต่อกัน ซึ่งรายละเอียดท่านทราบดีอยู่แล้วนั้น
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mb-5" style={{lineHeight: 3.5, paddingLeft: 60, paddingRight:60}}>
                                <table style={styles.table}>
                                    <tbody>
                                    <hr/>
                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                                ฉะนั้นโดยหนังสือฉบับนี้ ข้าพเจ้า
                                                <input type="text" style={styles.inputDataDetail}
                                                       name="notice_creditor"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.notice_creditor || ''}
                                                       onChange={this.handleOnChange}
                                                />
                                                จึงมีความประสงค์ให้ท่านชำระเงินค่าเช่าซื้อให้กับ
                                                <input type="text" style={styles.inputDataDetail}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                                ภายในกำหนด
                                                <input type="text" style={styles.inputDataNo}
                                                       name="within_days"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder="จน.วัน"
                                                       value={this.state.within_days || ''}
                                                       onChange={this.handleOnChange}
                                                />วัน นับตั้งแต่วันที่ท่านได้รับหนังสือบอกกล่าวฉบับนี้ หากท่านไม่ชำระภายในกำหนดระยะเวลาดังกล่าว
                                                <input type="text" style={styles.inputDataDetail}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={3}>
                                            <div className="form-inline">
                                                ขอถือเอาหนังสือฉบับนี้ เป็นการบอกเลิกสัญญาเช่าซื้อ พร้อมกับให้ท่านส่งมอบรถยนต์ที่เช่าซื้อคืนให้กับ
                                                <input type="text" style={styles.inputDataDetail}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                /> ณ ภูมิลำเนาของ
                                                <input type="text" style={styles.inputDataDetail}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                />ในสภาพเรียบร้อย หากท่านยังคงเพิกเฉยไม่ปฏิบัติตาม
                                                <input type="text" style={styles.inputDataDetail}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                /> มีความจำเป็นที่จะต้องดำเนินการตามขั้นตอนของกฏหมายต่อไป
                                                &nbsp;&nbsp;&nbsp;&nbsp;และหากท่านมีข้อสงสัยหรือมีเหตุประการใดกรุณาติดต่อกลับมาที่
                                                <input type="text" style={styles.inputDataDetail}
                                                       name=""
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                    //value={}
                                                       onChange={this.handleOnChange}
                                                />หมายเลขโทรศัพท์
                                                <input type="text" style={styles.inputDataDetail}
                                                       name="notice_tel_creditor"
                                                       className="form-control ml-2 mr-2"
                                                       placeholder=""
                                                       value={this.state.notice_tel_creditor || ''}
                                                       onChange={this.handleOnChange}
                                                /> หวังว่าคงได้รับความร่วมมือจากท่านเป็นอย่างดี
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mt-3">
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td width={'30%'}>&nbsp;</td>
                                        <td width={'30%'}>&nbsp;</td>
                                        <td className="text-center">ขอแสดงความนับถือ</td>
                                        <td width={'20%'}>&nbsp;</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mt-3">
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td width={'30%'}>&nbsp;</td>
                                        <td width={'32%'}>&nbsp;</td>
                                        <td className="text-center">
                                            <input type="text" style={{ ...styles.inputDataDetail}}
                                                   name="notice_payee"
                                                   className="form-control"
                                                   placeholder=""
                                                   value={this.state.notice_payee || ''}
                                                   onChange={this.handleOnChange}
                                            />
                                        </td>
                                        <td width={'20%'}>&nbsp;</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="col-12 text-right mt-3 mb-5">
                                <div className="card-block">
                                    <hr/>
                                    {
                                        this.state.notice_id === null ? (
                                            <button type="button"
                                                    className="btn btn-success btn-md mr-2"
                                                    style={{width: 120}}
                                                    //onClick={() => this.saveCarContract('add')}
                                            >
                                                <i className="icon-check"/>
                                                &nbsp;บันทึก
                                            </button>
                                        ) : <button type="button"
                                                    className="btn btn-warning btn-md mr-2"
                                                    style={{width: 120}}
                                                    //onClick={() => this.saveCarContract('edit')}
                                        >
                                            <i className="icon-pencil"/>
                                            &nbsp;แก้ไข
                                        </button>
                                    }

                                    {
                                        this.state.notice_id === null ? null : (
                                            <button type="button"
                                                    className="btn btn-primary btn-md mr-2"
                                                    style={{width: 120}}
                                                    //onClick={() => this.printCarContract()}
                                            >
                                                <i className="icon-printer"/>  พิมพ์ผู้เช่าซื้อ
                                            </button>

                                        )
                                    }
                                    {
                                        this.state.notice_id === null ? null : (
                                            <button type="button"
                                                    className="btn btn-primary btn-md mr-2"
                                                    style={{width: 120}}
                                                //onClick={() => this.printCarContract()}
                                            >
                                                <i className="icon-printer"/>  พิมพ์ผู้ค้ำ 1
                                            </button>

                                        )
                                    }
                                    {
                                        this.state.notice_id === null ? null : (
                                            <button type="button"
                                                    className="btn btn-primary btn-md mr-2"
                                                    style={{width: 120}}
                                                //onClick={() => this.printCarContract()}
                                            >
                                                <i className="icon-printer"/>  พิมพ์ผู้ค้ำ 2
                                            </button>

                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

const styles = {
    table: {
        width: '100%',
        maxWidth: '100%'
    },
    tdContentCenter: {
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    tdContentRight: {
        textAlign: 'right',
        verticalAlign: 'middle',
        justifyContent: 'right',
    },
    tdContentLeft: {
        textAlign: 'left',
        verticalAlign: 'middle'
    },
    buttonHeight: {
        height: 32,
    },
    inputData:{
        borderRadius: '5px',
        width: '450px',
    },
    inputData2:{
        borderRadius: '5px',
        width: '160px',
    },
    inputTax:{
        borderRadius: '5px',
        width: '220px',
    },
    inputDataNo:{
        borderRadius: '5px',
        width: '80px'
    },
    inputDataNum:{
        borderRadius: '5px',
        width: '156px'
    },
    inputDataDetail:{
        borderRadius: '5px',
        width: '280px',
    },
    overflowY: {
        overflow: 'scroll',
    },
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(DebtorsNotice)